.wrapper {
  display: flex;
  flex-direction: column;
  margin: 10px 0 20px 0;
  width: 100%;
}

.titleBlock {
  align-items: center;
  display: flex;
  width: 100%;
}

.backLink {
  align-items: center;
  color: var(--app-dark-color);
  display: flex;
  font-weight: 500;
  transition: none !important;
}

.backLink:hover {
  color: var(--app-dark-color);
}

.backLink svg {
  margin-right: 15px;
  margin-top: 3px;
}

.smallScreenBackLink {
  display: none;
}

.titleWrapper {
  align-items: baseline;
  display: flex;
  margin-bottom: 0 !important;
}

.titleWrapper h1 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: auto;
  margin: 0 auto;
  margin-bottom: 0px !important;
  padding-bottom: 10px;
  text-align: center;
}

.description {
  color: #667085;
  font-size: 16px;
  user-select: none;
}
.email{
  color: var(--Primary-Blue, #075CEB);
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
}
.button {
  align-items: center;
  background: #075CEB;
  border: 1px solid #075CEB;
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  color: white;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  font-size: 14px;
  justify-content: center;
  padding: 10px 16px;
  width: 115px;
  height: 40px;
  opacity: 1 !important;
  &_tutorial{
    position: relative;
    z-index: 100;
   }
}

/* @media screen and (min-width: 300px) and (max-width: 799px) {
  .smallScreenBackLink {
    display: inline-block;
    margin-right: auto;
  }

  .backLink {
    display: none;
  }
  
  .titleWrapper h1 {
    margin-left: -5px;
  }
} */
@media (max-width: 550px) {
  .titleWrapper{
    font-size: 24px;
    line-height: 28px;
  }
}
@media (max-width: 420px) {
  .titleWrapper{
    font-size: 18px;
    line-height: 28px;
  }
}