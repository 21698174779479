.buttonsInner{
    display: flex;
}
.buyCreditsBtn{
    align-items: center;
    background: #075CEB;
    border: 1px solid #075CEB;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    color: white;
    cursor: pointer;
    display: flex;
    font-weight: 600;
    font-size: 14px;
    justify-content: center;
    padding: 10px 16px;
    width: 137px;
    height: 40px;
    opacity: 1 !important;
    &_tutorial{
      position: relative;
      z-index: 100;
    }
}
.creditsQuantityBtn{
    border-radius: 8px;
    border: 1px solid var(--primary-50, #F9F5FF);
    background: var(--primary-50, #F9F5FF);
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    color: var(--Primary-Blue, #075CEB);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    width: 92px !important;
    min-width: 92px !important;
    height: 40px;
    margin-right: 12px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.datePicker{
    border-radius: 8px;
    border: 1px solid var(--gray-300, #D0D5DD);
    background: var(--White, #FFF);
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    height: 40px;
    margin-bottom: 22px;
    input {
        color: var(--gray-700, #344054);
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }
}
.tabs{
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid var(--gray-300, #D0D5DD);
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
    width: fit-content;
    margin-bottom: 22px;
    &__tab{
        color: var(--gray-700, #344054);
        font-family: Inter, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        padding: 10px 16px;
        cursor: pointer;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        &:first-child{
            border-right: 1px solid var(--gray-300, #D0D5DD);
        }
        &_active{
            background-color: #F9FAFB;
        }
    }
}
.badge{
    border-radius: 16px;
    background: var(--error-50, #FEF3F2);
    width: 42px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    span{
        color: var(--error-700, #B42318);
        text-align: center;
        font-family: Inter, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
    }
}