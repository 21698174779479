.header{
  padding: 0 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 72px;
  border-bottom: 2px solid #EAECF0;
  position: relative;
}
.menu{
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__item{
    margin-left: 10px;
  }
}
.tabButtons{
  padding: 12px 80px;
  &__btn{
    background: #FFFFFF;
    border-radius: 6px;
    padding: 8px 12px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #344054;
    border: none;
    & + &{
      margin-left: 4px;
    }
    &_active{
      color: #075CEB;
      background: #F9F5FF;
    }
  }
}
.table{
  border-top: 2px solid #EAECF0;
  padding: 20px 80px;
  &__header{
    display: flex;
    justify-content: space-between;
    h1{
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 38px;
      color: #101828;
      margin-bottom: 4px;
    }
    p{
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #667085;
      margin-bottom: 22px;
    }
    &_invite{
      background: #075CEB;
      border: 1px solid #075CEB;
      box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      height: 40px;
      width: 98px;
      font-family: 'Inter', sans-serif;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #FFFFFF;
      cursor: pointer;
    }
  }
  &__content{
    margin-top: 4px;
    background: #FFFFFF;
    border: 1px solid #EAECF0;
    box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    h2{
      margin: 20px 24px;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #101828;
      span {
        background: #F0F9FF;
        border-radius: 16px;
        color: #026AA2;
        font-weight: 500;
        font-size: 12px;
        margin-left: 7px;
        padding: 3px 8px;
      }
    }
  }
  &__invite{
    padding: 24px;
    h3{
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      color: #101828;
      margin-bottom: 8px;
    }
    p{
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #667085;
      margin-bottom: 20px;
    }
  }
}
.formLabel{
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  margin-bottom: 6px;
}
.formInput{
  background: #FFFFFF;
  border: 1px solid #D0D5DD;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  height: 44px;
}
.formButtons{
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  &__submit{
    background: #075CEB;
    border: 1px solid #075CEB;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    height: 44px;
    width: 100%;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFF;
    cursor: pointer;
  }
  &__cancel{
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    height: 44px;
    width: 100%;
    color: #344054;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
  }
}
@media (max-width: 1400px) {
  .table{
    padding: 20px;
  }
  .tabButtons {
    padding: 20px;
  }
  .header{
    padding: 0 20px;
  }
}
@media (max-width: 400px) {
.table{
  &__header{
    h1{
      font-size: 22px;
    }
  }
}
}
.mobile{
  display: block;
  position: relative;
  cursor: pointer;
  &__menu{
    position: absolute;
    z-index: 10;
    width: 240px;
    background: #FFFFFF;
    border: 1px solid #F2F4F7;
    box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    border-radius: 8px;
    right: 85px;
    top: -20px;
    &_avatar{
      padding: 12px 31px 12px 16px;
      border-bottom: 1px solid #F2F4F7;
      img{
        margin-right: 12px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: contain;
      }
      p{
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #344054;

      }
    }
    &_item{
      a{
        display: flex;
        font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #344054;
        padding: 10px 16px;
        align-items: center;
      }
    }
    p{
      margin-left: 12px;
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #344054;
      margin-bottom: 0;
    }
    &_itemFooter{
      border-top: 1px solid #F2F4F7;
      display: flex;
      padding: 10px 16px;
      align-items: center;
    }
  }
}