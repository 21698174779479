.inner{
  width: 100vw;
  height: 100vh;
  min-height: 100%;
  backdrop-filter: blur(2px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  &__modal{
    background: var(--white, #FFF);
    box-shadow: 0 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08);
    padding: 24px;
    max-width: 400px;
    width: 100%;
    border-radius: 12px;
    background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(45deg, #0A5BEB, #9233F4) border-box;
    border: 1px solid transparent;

    h1{
      color: var(--gray-900, #101828);
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px;
      margin-top: 18px;
      margin-bottom: 8px;
    }
    p{
      color: var(--gray-500, #667085);
      text-align: center;
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      max-width: 322px;
      width: 100%;
      margin-bottom: 32px;
    }
  }
  &__modalBtns{
    display: flex;
    justify-content: space-between;
    &_fourthStep{
      max-width: 390px;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
    }
  }
}
.skipBtn{
  color: var(--primary-blue, #075CEB);
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  height: 44px;
  width: 100%;
  background-color: transparent;
  border: none;
  cursor: pointer;
}