.title{
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  margin-top: 5px;
}
.description{
  color: #667085;
  font-size: 16px;
  margin-bottom: 30px;
}
.wrapper{
  column-gap: 30px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 312px);
  row-gap: 30px;
}
.link{
  & + &{
    width: 100%;
  }
}
@media (max-width: 1360px) {
  .wrapper{
    grid-template-columns: repeat(auto-fill, 290px);
  }
}
@media (max-width: 1290px) {
  .wrapper{
    grid-template-columns: repeat(auto-fill, 280px);
  }
}
@media (max-width: 1160px) {
  .wrapper{
    grid-template-columns: repeat(auto-fill, 312px);
    row-gap: 16px;
    column-gap: 16px;
  }
}
@media (max-width: 695px) {
  .wrapper{
    grid-template-columns: none;
    row-gap: 16px;
    column-gap: 16px;
  }
}