.card{
  padding: 25px 15px;
  background-color: #EDF4FF;
  border: 1px solid #E5E7EB;
  border-radius: 12px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  position: relative;
  text-decoration: none;
  &__statusNoIntegration,
  &__statusWithIntegration{
    align-items: center;
    border-radius: 50px;
    display: flex;
    font-size: 10px;
    padding: 2px 6px;
    position: absolute;
    top: 20px;
    right: 20px;
    text-transform: uppercase;
    width: fit-content;
  }
  &__statusWithIntegration{
    background: rgba(2, 201, 79, 0.1);
    border: 1px solid rgba(2, 201, 79, 0.1);
    color: #02C94F;
  }
  &__statusNoIntegration{
    background: rgba(243, 59, 18, 0.1);
    border: 1px solid rgba(243, 59, 18, 0.1);
    color: #F33B12;
    &_coming{
      background: rgba(145, 52, 244, 0.10);
      border: 1px solid rgba(145, 52, 244, 0.30);
      span{
        background: var(--primary-gradient, linear-gradient(90deg, #0A5BEB 0%, #9233F4 100%));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  &__statusIcon {
    margin-right: 6px;
  }
  &__img,
  &__generalImg{
    width: 172px;
    min-height: 70px;
    margin-bottom: 10px;
    img{
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
  &__img_general{
    width: 70px;
    height: 70px;
    margin-bottom: 10px;
    img{
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
  &__generalImg{
    img{
      width: 150px;
    }
  }
  &__title{
    margin-bottom: 10px;
    text-decoration: none;
    color: black;
  }
  &__text{
    max-width: 280px;
    width: 100%;
    text-decoration: none;
    color: black;
    min-height: 88px;
  }
  &__btn{
    align-self: center;
    background: #075CEB;
    border: 1px solid #075CEB;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    color: #FFFFFF;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    height: 32px;
    margin-top: 30px;
    width: 100%;
    transition: all .4s;
    &:hover{
      border-color: #40a9ff;
      background: #40a9ff;
    }
  }
  &__btn_mobile{
    width: 100%;
    margin-right: 10px;
    min-width: 135px;
  }
  &_tutorial{
    position: relative;
    z-index: 100;
  }
}
@media (max-width: 1350px) {
  .card{
    &__btn_mobile{
      min-width: 124px;
    }
  }
}
@media (max-width: 1350px) {
  .card{
    &__btn_mobile{
      min-width: 119px;
    }
  }
}
@media (max-width: 1160px) {
  .card{
    &__title{
      font-weight: 500;
      font-size: 16px;
      line-height: 25px;
      margin-bottom: 10px;
    }
  }
}
@media (max-width: 695px) {
  .card{
    height: auto;
    padding: 16px 20px 11px 16px;
    &__text{
      max-width: 100%;
      width: 100%;
      margin-bottom: 10px;
    }
    &__btn{
      width: 100%;
      margin: 0 4px;
    }
    &__btn_mobile{
      width: 100%;
    }
    &__title{
      margin-bottom: 6px;
    }
  }
}