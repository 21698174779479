.inner{
  padding: 10px 20px 26px 20px;
  &__header{
    // height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.tableInner{
  background: #FFFFFF;
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  h2 {
    color: #101828;
    font-weight: 500;
    font-size: 18px;
    padding: 20px;
    span {
      background: #F0F9FF;
      border-radius: 16px;
      color: #026AA2;
      font-weight: 500;
      font-size: 12px;
      margin-left: 7px;
      padding: 3px 8px;
    }
  }
  &_tutorial{
    position: relative;
    z-index: 100;
    mix-blend-mode: color-burn;
  }
  &__header{
    padding: 20px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  &__title{
    color: var(--gray-900, #101828);
    font-family: Inter, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 0;
    min-width: 120px;
  }
  &__status{

  }
}
.link{
  color: var(--gray-500, #667085);
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.modal{
  padding: 15px 40px;
  position: relative;
  h2{
    text-align: center;
    margin-bottom: 15px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: #111827;
  }
  &__checkbox :global .ant-checkbox-inner{
    border: 1px solid #D0D5DD;
    border-radius: 4px;
  }
  &__input{
    border: 1px solid #D0D5DD;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    height: 42px;
  }
  &__select :global .ant-select-selector {
    border: 1px solid #D0D5DD !important;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05) !important;
    border-radius: 8px !important;
    font-size: 16px;
    height: 42px !important;
    display: flex;
    align-items: center;
  }
  &__subtitle{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #101828;
    margin-top: 15px;
    margin-bottom: 5px;
  }
  &__categoryTitle{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    margin-top: 10px;
    color: #101828;
    margin-bottom: 6px;
  }
  &__inputLabel{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #111827;
    margin-bottom: 0;
  }
  &__btn{
    margin: 20px auto 0;
    font-family: 'Inter', sans-serif;
    color: white;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
    background: #075CEB;
    border: 1px solid #075CEB;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    cursor: pointer;
    padding: 5px 10px;
    height: 44px;
    width: 100%;
  }
  &__closeBtn{
    border: none;
    background-color: transparent;
    position: absolute;
    top: 2px;
    right: 8px;
    font-size: 20px;
    cursor: pointer;
  }
}
.servicesIcons{
  display: flex;
  &__icon{
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.badge{
  color: var(--error-700, #B42318);
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  border-radius: 16px;
  background: var(--error-50, #FEF3F2);
  width: 42px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sendButton{
  transition: all .5s;
  &:hover{
    color: #40a9ff !important;
  }
}
@media (max-width: 1160px) {
  .hidden-column {
    display: none;
  }
}
@media (max-width: 550px) {
  .modal{
    padding: 15px 0;
  }
  .inner{
    &__header{
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 20px;
    }
  }
}
