.inner {
  padding: 32px 100px 32px 32px;
  width: 100%;
}

.title {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.doubleBlockArea {
  column-gap: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 20px;
}

.blockWrapper {
  background: #FFFFFF;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 20px 15px 0 15px;
  &__agencyInfo{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #131313;
    margin-bottom: 20px;
  }
  &__integrationTitle{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #131313;
    margin-bottom: 12px;
  }
  h3 {
    color: #131313;
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 20px;
  }

  h4 {
    color: #101828;
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    text-align: center;
  }

  button {
    background: #075CEB;
    border: 1px solid #075CEB;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    font-weight: 600;
  }

  &__label {
    color: #101828;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    margin-bottom: 5px;
  }

  &__input {
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    font-size: 16px;
    height: 44px;
    width: 100%;
  }

  &__select :global .ant-select-selector {
    border: 1px solid #D0D5DD !important;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05) !important;
    border-radius: 8px !important;
    color: #667085;
    height: 42px !important;
    display: flex;
    align-items: center;

    & :global .ant-select-selection-item {
      font-size: 12px;
      font-weight: 600;
    }
  }

  &__upload {
    background: rgba(237, 244, 255, 0.4) !important;
    border-radius: 8px !important;
    height: 250px !important;
    margin-bottom: 10px;
    position: inherit !important;
    width: 100% !important;

    &__text {
      align-items: center;
      color: #075CEB;
      display: flex;
      flex-direction: column;
      font-size: 12px;
      justify-content: center;
      line-height: 18px;
    }

    svg {
      margin-bottom: 15px;
    }
  }
  &__tutorial{
    position: relative;
    z-index: 100;
  }
}

.logoImg {
  height: 150px;
  width: 150px;
  cursor: pointer;
  margin-bottom: 10px;
  object-fit: contain;
}

.member {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  &__name {
    color: #344054;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  &__email {
    color: #667085;
    font-size: 14px;
  }

  svg {
    cursor: pointer;
    margin-left: 10px;
  }

  svg:hover path {
    stroke: red;
  }
}

.integrationCards {
  column-gap: 15px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 312px);
  row-gap: 15px;
}

@media (max-width: 1160px) {
  .inner {
    padding: 1px 14px 45px;
  }
  .title {
    margin-top: 5px;
    margin-bottom: 21px;
  }
  .blockWrapper {
    padding: 12px 14px 24px;

    h3 {
      font-weight: 500;
      font-size: 16px;
      line-height: 25px;
      margin-bottom: 10px;
    }
  }
}

@media (max-width: 950px) {
  .integrationCards {
    grid-template-columns: repeat(auto-fill, 340px);
  }
  .title {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #101828;
  }
}

@media (max-width: 750px) {
  .integrationCards {
    grid-template-columns: none;
  }
}

@media (max-width: 650px) {
  .doubleBlockArea {
    display: block;
  }
  .blockWrapper {
    h3 {
      margin-bottom: 4px;
    }
  }
}
@media (max-width: 480px) {
  .member {
    &__email {

      width: 170px;
      word-break: break-word;
    }
  }
}