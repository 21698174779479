@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
  overflow-x: hidden;
}

ul,
ol,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

button:focus,
input:focus {
  outline: transparent;
}

html {
  box-sizing: border-box;
}

a {
  display: inline-block;
  text-decoration: none;
}

ul,
li {
  list-style-type: none;
}
.facebookModal .ant-checkbox-wrapper + .ant-checkbox-wrapper{
  margin-left: 0;
  margin-top: 5px;
}
.facebookModal .ant-form-item{
  margin-bottom: 15px;
}
.clientSignIn .ant-checkbox-wrapper + .ant-checkbox-wrapper{
  margin-left: 0 !important;
}
.privatePanelSignIn .ant-form-item{
  width: 100%;
}
.privatePanelSignIn .ant-form-item + .ant-form-item{
  margin-right: 12px;
}
.powered{
  position: absolute;
  bottom: 10px;
  right: 30px;
  color: #667085;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  opacity: 0.9;
}

/* common styles */
.primaryButton{
  border-radius: 8px;
  border: 1px solid  #075CEB;
  background: #075CEB;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  color: #FFF;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.primaryButton:hover{
  border-color: #40a9ff;
  background: #40a9ff;
  transition: all .4s
}
.secondaryButton{
  border-radius: 8px;
  border: 1px solid #D0D5DD;
  background: #FFF;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #344054;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
}


/* Keyframes for the drawing animation */
@keyframes draw {
  0%, 25% {
    width: 0;
  }
  50% {
    width: 50%;
  }
  100% {
    width: 100%;
  }
}

/* Keyframes for the circle animation */
/*@keyframes circle {*/
/*  0%, 100% {*/
/*    transform: translateX(0) rotate(0deg);*/
/*  }*/
/*  50% {*/
/*    transform: translateX(50px) rotate(180deg);*/
/*  }*/
/*}*/

/* Keyframes for the drawing animation */
@keyframes draw {
  0%, 100% {
    width: 0;
    transform: translateY(0);
  }
  25%, 75% {
    width: 100%;
    transform: translateY(-10px);
  }
  50% {
    width: 100%;
    transform: translateY(10px);
  }
}

/* Container for the animation */
.animation-container {
  position: relative;
  width: 200px;
  height: 50px; /* Set the container height */
  background-color: #f0f0f0;
  overflow: hidden;
}

.line {
  width: 0;
  height: 2px; /* Set the line thickness */
  background-color: #0A5BEB;
  animation: draw 4s linear infinite;
}

/* Apply basic styles to ensure the content is initially hidden */
.fade-in-content {
  opacity: 0;
  transform: translateY(20px); /* Optional: Add a slight vertical translation */
  transition: opacity 1s ease, transform 1s ease;
}

/* Apply the fade-in animation when the content becomes visible */
.fade-in-content.fade-in {
  opacity: 1;
  transform: translateY(0);
}
.block {
  width: 100px;
  height: 100px;
  background-color: #0074d9; /* Block color */
  margin: 0 20px;
  position: relative;
  z-index: 2;
  cursor: pointer;
}
.tutorialAnimatedArrow{
  position: absolute;
  z-index: 100;
}
.tutorialAnimatedArrow svg path:nth-child(2) {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 3s linear forwards infinite;
}
.tutorialAnimatedArrow svg path:first-child{
  opacity: 0;
  animation: arrowOpacity 3s ease-in infinite !important ;
}
@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes arrowOpacity {
  to {
    opacity: 1;
  }
}
.tutorialButton{
  position: fixed;
  bottom: 15px;
  right: 20px;
  cursor: pointer;
}
.tutorialTooltip{
  position: absolute;
  color: #FFF;
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  border-radius: 8px;
  background: #191559;
  line-height: 18px;
  padding: 8px 12px;
  top: -37px;
  right: -5px;
}
.tutorialTooltip:after{
  content: " ";
  position: absolute;
  top: 100%;
  left: 71%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #191559 transparent transparent transparent;
}
.sendLinkUrlInput{
  background-color: #fff !important;
  user-select: none;
}
.creditsTableDatePicker .ant-picker-date-panel, .ant-picker-content th{
  color: #344054;
  font-family: Inter , sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.creditsTableDatePicker .ant-picker-cell-in-view{
  color: #344054;
  font-family: Inter , sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.creditsTableDatePicker .ant-picker-header button{
  color: #344054;
  font-family: Inter , sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.creditsTableDatePicker .ant-picker-panel-container{
  border-radius: 8px;
}
.clientSelect .ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border-radius: 8px;
  border: 1px solid #D0D5DD;
  background: #FFF;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  height: 44px;
}
.clientSelect .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
  line-height: 44px;
}
.clientSelect .ant-select-single .ant-select-selector .ant-select-selection-search{
  top: -2px;
}
.clientSelect .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
  height: 44px;
}
.serviceCheckbox .ant-checkbox-inner{
  border-radius: 50%;
}
.clientSelect .ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border-radius: 8px;
  border: 1px solid #D0D5DD;
  background: #FFF;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  height: 44px;
}
.clientSelect .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
  line-height: 44px;
}
.clientSelect .ant-select-single .ant-select-selector .ant-select-selection-search{
  top: -2px;
}
.clientSelect .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
  height: 44px;
}
.serviceCheckbox .ant-checkbox-inner{
  border-radius: 50%;
}
.clientSelect .ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border-radius: 8px;
  border: 1px solid #D0D5DD;
  background: #FFF;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  height: 44px;
}
.clientSelect .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
  line-height: 44px;
}
.clientSelect .ant-select-single .ant-select-selector .ant-select-selection-search{
  top: -2px;
}
.clientSelect .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
  height: 44px;
}
.serviceCheckbox .ant-checkbox-inner{
  border-radius: 50%;
}
.ant-progress-line{
  width: 73%;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: fit-content;
  min-width: 580px;
  padding: 8px 6px;
  background: rgba(0, 0, 0, 0.75);
  border-radius: 2px;
  color: #fff;
  text-align: center;
  position: absolute;
  z-index: 1;
  bottom: 120%;
  left: 30%;
  margin-left: -90px;
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}


.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 52.5%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.75) transparent transparent transparent;
}

.addressTooltip {
  position: absolute;
  top: 44px;
  right: 10px;
  z-index: 10000;
  cursor: pointer;
}
.addressTooltip.tooltip .tooltiptext {
  border-radius: 8px;
  font-size: 12px;
  max-width: 198px;
  left: 10.5%;
  z-index: 1000;
  text-align: center;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
}
.addressButtonsTooltip.tooltip .tooltiptext {
  border-radius: 8px;
  font-size: 12px;
  max-width: 198px;
  left: -560%;
  z-index: 1000;
  text-align: center;
  font-family: Inter, sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
}
.gradient-progress {
  background: linear-gradient(to right, #0A5BEB, #9233F4);
}
.sendLinkButton.ant-select,
.resendLinkButton.ant-select{
  border-radius: 8px;
  border: 1px solid #075CEB;
  background: #075CEB;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}
.sendLinkButton.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  background-color: transparent;
  border: none;
  height: 44px;
  width: 205px;
  color: #fff;
  border-radius: 8px;
  padding: 0 32px;
}
.sendLinkButton .ant-select-selection-placeholder{
  color: #fff;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.sendLinkButton .ant-select-selection-item{
  color: #fff;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  display: flex;
  align-items: center;
}
.sendLinkButton .ant-select-arrow svg,
.resendLinkButton .ant-select-arrow svg{
  fill: #fff;
}
.sendLinkButton .ant-select-arrow,
.resendLinkButton .ant-select-arrow{
  right: 30px;
  top: 52%;
}
.clientTableProgress .ant-progress-line{
  width: 153px;
}
.sendLinkButton .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder,
.resendLinkButton .ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
  line-height: 43px !important;
}
.resendLinkButton .ant-select-selection-item{
  color: #fff;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  align-items: center;
}

.resendLinkButton .ant-select-selection-placeholder{
  color: #fff;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.resendLinkButton.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  background-color: transparent;
  border: none;
  height: 44px;
  width: 160px;
  color: #fff;
  border-radius: 8px;
  padding: 0 16px;
}
.resendLinkButton .ant-select-arrow{
  right: 13px;
}
.ant-select-dropdown{
  border-radius: 8px !important;
  padding: 0 !important;
}
.ant-select-item{
  min-height: 40px !important;
}
.ant-select-item-option{
  align-items: center !important;
}
.ant-progress-bg{
  background: linear-gradient(to right, #0A5BEB, #9233F4) !important;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector{
  background-color: #075ceb !important;
}
.sendLinkImageLoader .ant-progress-status-success .ant-progress-text,
.feedbackLinkImageLoader .ant-progress-status-success .ant-progress-text{
  display: none;

}
.sendLinkImageLoader,
.feedbackLinkImageLoader{
  border-radius: 8px;
  border: 1px solid #EAECF0;
  background: #FFF;
  padding: 16px;
}
.sendLinkImageLoader .ant-progress-line,
.feedbackLinkImageLoader .ant-progress-line{
  width: 310px;
}
.sendLinkImageLoader .ant-progress-show-info .ant-progress-outer,
.feedbackLinkImageLoader .ant-progress-show-info .ant-progress-outer{
  padding-right: 0;
}
.custom-image-upload-container {
  position: relative;
  min-width: 80px;
  min-height: 80px;
  max-width: 80px;
  max-height: 80px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #ccc;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-label {
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.file-input {
  display: none;
}

.uploaded-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.upload-button {
  border: none;
  cursor: pointer;
}

.action-buttons {
  position: absolute;
  cursor: pointer;
  /*bottom: 4px;*/
  /*right: 4px;*/
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 79px;
  min-height: 80px;
  max-width: 80px;
  max-height: 80px;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
}

.delete-button {
  border: none;
  cursor: pointer;
  margin-bottom: 4px;
  background-color: transparent;
  transition: all .5s;
  margin-top: 3px;
}
.action-buttons svg path{
  stroke: #fff;
}
#feedbackModalForm{
  padding: 30px;
}

#feedbackModalForm textarea.ant-input{
  min-height: 128px;
}

