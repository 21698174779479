.tutorialDots{
  display: flex;
  justify-content: space-between;
  width: 68px;
  margin: 0 auto 20px;
}
.tutorialDot{
  border-radius: 4px;
  background: var(--primary-100, #F4EBFF);
  width: 8px;
  height: 8px;
  cursor: pointer;
  &_current{
    background: var(--primary-blue, #075CEB);
  }
}