.inner{
  display: flex;
  justify-content: space-between;
  width: 446px;
  margin: 0 auto;
  &.inner_single_service {
    justify-content: center;
  }
}
.item{
  display: flex;
  position: relative;
  flex-flow: row-reverse;
  &__lineImg{
    height: 2px;
    margin-top: 15px;
  }
  &__dot{
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    width: 60px;
  }
  &__dotImg{
    position: relative;
    z-index: 5;
  }
  &__dot_step{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 28px;
    color: #9CA3AF;
    margin-top: 8px;
  }
  &__dot_service{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #131313;
  }
}
@media screen and (max-width: 500px){
  .inner{
    width: 326px;
  }
}
