.inner{
  border-radius: 6px;
  background: #EDF4FF;
  margin-bottom: 6px;
  padding: 9px 12px 11px;
  &__item{
    display: flex;
    align-items: center;
    margin-bottom: 13px;
    p{
      color: var(--Primary-Blue, #075CEB);
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      margin-left: 12px;
      margin-bottom: 0;
    }
  }

}