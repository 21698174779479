.inner{
  background-color: #FFFFFF;
  border-right: 1px solid #EAECF0;
  padding: 32px 16px;
  width: 311px;
  min-width: 311px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  &__menuDesktop{
    display: block;
  }
  &__navDesktop{
    display: block;
    margin-top: auto;
  }
}
.logo {
  margin-bottom: 70px;
  width: 170px;
  margin-top: 10px;
  margin-left: 14px;
}
.navigation{
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}
.menuItem{
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 6px;
  padding: 8px;
  text-transform: capitalize;
  position: relative;
  a, span{
    align-items: center;
    color: #344054;
    display: flex;
    text-decoration: none;
    width: 100%;
  }
  &.active{
    background: #EDF4FF;
    border-radius: 6px;
    a{
      color: #075CEB;
      font-size: 16px;
      font-weight: 600;
    }
    p{
      color: #075CEB !important;
    }
    svg path{
      stroke: #075CEB;
    }
    &_settings{
      border-radius: 6px;
      background: var(--gray-50, #F9FAFB);
    }
  }

  svg{
    width: 24px;

    path{
      stroke: #667085;
    }
  }
  &__tutorial{
    position: relative;
    z-index: 1000;
  }
}
.userAvatar{
  display: flex;
  align-items: center;
  justify-content: space-between;
  img{
    object-fit: contain;
    width: 100%;
    border-radius: 50%;
    height: 100%;
  }
  p{
    margin-bottom: 0;
  }
  svg{
    cursor: pointer;
  }
}
.mobile{
  display: none;
}
.credits{
  &__inner{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__content{
    display: flex;
    align-items: center;
    p{
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }
  &__badge{
    color: var(--Primary-Blue, #075CEB);
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border-radius: 16px;
    background: #DCE9FF;
    display: flex;
    padding: 2px 10px;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    margin-right: 41px;
  }
  &_buyNew{
    border-radius: 6px;
    background: #EDF4FF;
    padding: 8px 12px;
  }
}
.buyMore{
  padding: 20px 16px;
  border-radius: 8px;
  background: var(--gray-50, #F9FAFB);
  p:first-child{
    color: var(--gray-900, #101828);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 4px;
  }
  p:nth-child(2){
    color: var(--gray-500, #667085);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 16px;
  }
  a + a{
    margin-left: 12px;
  }
  &__link{
    color: var(--gray-500, #667085);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
  a:nth-child(2) > div{
    color: #075CEB;
  }
}
@media (max-width: 1260px) {
  .inner{
    width: 230px;
    min-width: 230px;
  }
}
@media (max-width: 1160px) {
  .inner{
    width: 100%;
    min-width: 100%;
    height: 80px;
    min-height: 80px;
    padding: 16px 30px;
    flex-direction: row;
    border-bottom: 1px solid #EAECF0;
    align-items: center;
    justify-content: space-between;
    position: relative;
    &__menuDesktop{
      display: none;
    }
    &__navDesktop{
      display: none;
    }
  }
  .divider{
    display: none;
  }
  .logo {
    margin-bottom: 0;
    width: 137px;
    margin-left: 0;
  }
  .mobile{
    display: block;
    position: absolute;
    right: 15px;

    &__menu{
      position: absolute;
      z-index: 10;
      width: 100vw;
      background: #FFFFFF;
      border: 1px solid #F2F4F7;
      box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
      right: -16px;
      top: 52px;
      &_avatar{
        padding: 12px 31px 12px 16px;
        img{
          margin-right: 12px;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: contain;
        }
        p{
          font-family: 'Inter', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #344054;

        }
      }
      &_item{
        cursor: pointer;
        a{
          display: flex;
          font-family: 'Inter', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #344054;
          padding: 12px 30px;
          align-items: center;
        }
        li{
          display: flex;
          font-family: 'Inter', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #344054;
          padding: 12px 30px;
          align-items: center;
        }
        & + &{
          margin-top: 8px;
        }
      }
      p{
        margin-left: 12px;
        color: var(--Gray-900, #101828);
        font-family: Inter, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 0;
      }
      &_itemFooter{
        border-top: 1px solid #F2F4F7;
        display: flex;
        padding: 10px 16px;
        align-items: center;
      }
    }
    &__settings{
      a{
        padding: 0;
        color: var(--Gray-900, #101828);
        font-family: Inter, sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
    }
  }
}
.withoutLinkItem{
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 6px;
  padding: 8px;
  text-transform: capitalize;
  position: relative;
  p{
    color: rgb(52, 64, 84);
    margin-bottom: 0;
    margin-left: 12px;
    font-family: 'Inter', sans-serif;
  }
}
@media (max-width: 560px) {
  .mobile{
    &__menu{
      right: 0;
      top: 54px;
    }
  }
}
@media (max-width: 480px) {
  .inner{
    padding: 16px 1px 16px 16px;
  }
  .mobile{
    right: 0;
  }
}