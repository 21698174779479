.inner{
  padding: 10px 8px;
}
.content{
  margin-top: -6px;
  &__description{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #667085;
    margin-bottom: 4px;
    &_text{
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #131313;
    }
    &_link{
      font-family: 'Inter', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #075CEB;
    }
  }
  &__item{
    & + & {
      margin-top: 10px;
    }
  }
}
.buttons{
  display: flex;
  justify-content: space-between;
  margin-top: 58px;
  button{
    width: 100%;
    height: 40px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 8px;
    &:first-child{
      color: #075CEB;
      background: #F9F5FF;
      border: 1px solid #F9F5FF;
      box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    }
    &:nth-child(2){
      background: #FFFFFF;
      border: 1px solid #D0D5DD;
      box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
      color: #344054;
    }
    &:nth-child(3){
      background: #075CEB;
      border: 1px solid #075CEB;
      box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
      color: #FFFFFF;
    }
  }
  button + button{
    margin-left: 10px;
  }
}