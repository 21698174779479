.inner{
  padding: 32px 32px 30px;
  &__header{
    display: flex;
    justify-content: space-between;
    align-items: center;

  }
}
.tabs{
  display: flex;
  border-bottom: 1px solid #EAECF0;
  margin-top: 24px;
  margin-bottom: 25px;
  &__tab{
    color: var(--gray-500, #667085);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding: 0 4px;
    cursor: pointer;
    &_active{
      color: var(--Primary-Blue, #075CEB);
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      border-bottom: 1px solid #075CEB;
      padding-bottom: 17px;
    }
    & + &{
      margin-left: 20px;
    }
  }
}
.addClient{
  align-items: center;
  background: #075CEB;
  border: 1px solid #075CEB;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  color: white;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  font-size: 14px;
  justify-content: center;
  padding: 10px 16px;
  width: 173px;
  min-width: 173px;
  height: 40px;
  opacity: 1 !important;
  &_tutorial{
    position: relative;
    z-index: 100;
  }
}
.icon{
  transition: all .3s;
  &:hover{
    svg{
      path{
        stroke: rgb(7, 92, 235);
      }
    }
  }

}
@media (max-width: 430px) {
  .inner{
    padding: 16px;
  }
}