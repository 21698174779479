.search{
  background-color: white;
  border: 1px solid #D0D5DD;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  display: flex;
  align-items: center;
  height: 50px;
  padding: 15px 10px;
  justify-content: flex-start;
  margin-bottom: 16px;
  font-size: 16px;
  width: 400px;
  &__input{
    background: transparent;
    border: none;
    box-shadow: none;
    font-size: 16px;
    width: 100%;
  }
}
@media (max-width: 780px) {
  .search{
    width: 259px;
  }
}

@media (max-width: 550px) {
  .search{
    width: 100%;
  }
}
