.inner {
  display: flex;
  height: 100vh;

  &__left {
    background-color: #fff;
    width: 50%;
    height: 100%;
  }

  &__right {
    background-color: #F2F4F7;
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.logo {
  margin: 26px 0 0 32px;
}

.form {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;

  &__content {
    max-width: 360px;
    width: 100%;
  }

  h1 {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    letter-spacing: -0.02em;
    color: #101828;
    margin-bottom: 12px;
  }

  p {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #667085;
    margin-bottom: 32px;
  }

  &__label {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    margin-bottom: 6px;
  }

  &__input {
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    height: 44px;
  }

  &__submit {
    background: #075CEB;
    border: 1px solid #075CEB;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    height: 44px;
    width: 100%;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
  }
}

@media (max-width: 1370px) {
  .inner {
    &__right {
      img {
        width: 500px;
      }
    }
  }
}
@media (max-width: 960px) {
  .inner {
    &__right {
      img {
        width: 380px;
      }
    }
  }
}
@media (max-width: 740px) {
  .inner {
    &__right{
      display: none;
    }
    &__left{
      width: 100%;
      background-color: #F2F4F7;
    }
  }
  .form{
    padding: 0 20px;
  }
}
