.inner{
  padding: 32px;
  &__header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 45px;
  }
}
.archiveBtn{
  border-radius: 8px;
  border: 1px solid var(--primary-50, #F9F5FF);
  background: var(--primary-50, #F9F5FF);
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
  color: var(--Primary-Blue, #075CEB);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  width: 84px;
  height: 40px;
  cursor: pointer;
}
.resendBtn{
  align-items: center;
  background: #075CEB;
  border: 1px solid #075CEB;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  color: white;
  cursor: pointer;
  display: flex;
  font-weight: 600;
  font-size: 14px;
  justify-content: center;
  padding: 10px 16px;
  width: 136px;
  height: 40px;
}
.permissionsList{
  color: var(--gray-900, #101828);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.001px;
}
.configBtn{
  border-radius: 8px;
  border: 1px solid var(--gray-300, #D0D5DD);
  background: var(--White, #FFF);
  height: 44px;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 12px;
  min-width: 44px;
}
.buttons{
  display: flex;
  &_mobile{
    display: none;
  }
}
@media (max-width: 768px) {
  .itemHeader{
    flex-direction: column;
    align-items: start !important;
  }
  .buttons{
    margin-top: 15px;
  }
}
@media (max-width: 480px) {
  .inner{
    padding: 16px;
  }
  .buttons{
    display: none;
    &_mobile{
      display: flex;
      width: 100%;
      margin-bottom: 30px;
      .archiveBtn{
        width: 100% !important;
      }
    }
  }

}