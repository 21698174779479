.inner{
  padding: 30px 24px;
  h2{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    color: #101828;
    margin-bottom: 28px;
  }
  p{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #101828;
    margin-bottom: 20px;
  }
}
.checkboxItem{
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #344054;
  & + &{
    margin-top: 16px;
  }
}