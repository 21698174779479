.service{
  &__item{
    border-radius: 8px;
    border: 1px solid var(--gray-200, #EAECF0);
    background: var(--White, #FFF);
    & + &{
      margin-top: 12px;
    }
  }
  &__img{
    width: 46px;
    height: 46px;
  }
  &__itemHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px 16px;
    border-bottom:  1px solid var(--gray-200, #EAECF0);;
  }
  &__checkbox .ant-checkbox-inner{
    border-radius: 50%;
  }
  &__desc{
    display: flex;
    align-items: center;
    p{
      color: var(--gray-700, #344054);
      font-family: Inter, sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      margin-left: 16px;
      margin-bottom: 0;
    }
  }
  &__footer{
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p{
      margin-left: 12px;
      color: var(--gray-500, #667085);
      font-family: Inter, sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 0;
    }
    button{
      color: var(--Primary-Blue, #075CEB);
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
  }
}