.modal{
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(38, 37, 61, 0.6);
    opacity: 0;
    transition: .5s all;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;

}
.modal.active{
    opacity: 1;
    pointer-events: all;
}
.modalContent{
    background: #F8F7F8;
    border-radius: 4px;
    width: 700px;
    min-height: 600px;
    max-height: 90vh;
    overflow-y: auto;
    //transform: scale(0.5);
    transition: .4s all;
    &_tutorial{
        z-index: 100;
        position: relative;
    }
}
.modalContent.active{
    transform: scale(1);
}
@media screen and (max-width: 600px){
    .modalContent{
        padding: 20px;
        margin-top: -25px;
    }
}
@media screen and (max-width: 480px){
    .modal{
        overflow-y: scroll;
        margin-top: 0;
    }
}