.button{
  padding: 10px 16px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054;
  border: 1px solid #D0D5DD;
  background-color: #FFFFFF;
  cursor: pointer;
  &:first-child{
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &:last-child{
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  &_active{
    background-color: #F9FAFB;
  }
}
@media (max-width: 660px) {
  .inner{
    margin-bottom: 10px;
  }
}
@media (max-width: 365px) {
  .button{
    padding: 10px 15.5px;
  }
}