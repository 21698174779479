.inner{
  padding: 32px;
  width: 100%;
}
@media (max-width: 1260px) {
  .inner{
   padding: 32px 10px;
  }
}
@media (max-width: 1160px) {
  .inner{
    padding: 20px 26px 46px 30px;
  }
}
@media (max-width: 695px) {
  .inner{
    padding: 20px 20px 34px 20px;
  }
}
@media (max-width: 430px) {
  .inner{
    padding: 20px 8px 34px;
  }
}