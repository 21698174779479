
.inner{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #EDF4FF;
  min-height: 100vh;
  height: 100%;
  position: relative;
  overflow: hidden;
  &__decoration{
    position: absolute;
  }
  &__multiStepBeginning{
    background: #FFFFFF;
    box-shadow: 0 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    border-radius: 12px;
    padding: 47px 64px;
    z-index: 5;
    max-width: 827px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__bigText{
    margin-top: 24px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 140%;
    color: #101828;
    text-align: center;
    margin-bottom: 12px;
  }
  &__smallText{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    text-align: center;
    color: #667085;
    margin-bottom: 40px;
  }
  &__multiStepBeginning_steps{
    border-radius: 8px;
    border: 1px solid var(--gray-200, #EAECF0);
    background: var(--White, #FFF);
    box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.06), 0 1px 3px 0 rgba(16, 24, 40, 0.10);
    width: 100%;
    margin-top: 40px;
  }
}
.formBlock{
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 26px 40px;
  position: relative;
  width: 440px;
  h1{
    margin: 30px 0 12px 0;
    text-align: center;
  }
  &__description{
    color: #667085;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 30px;
    text-align: center;
  }
  &__form{
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
  }
  &__label{
    color: #344054;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 6px;
    font-family: Inter, sans-serif;
  }
  &__input{
    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    font-size: 16px;
    height: 44px;
    width: 100%;
  }
  &__select :global .ant-select-selector {
    border: 1px solid #D0D5DD !important;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05) !important;
    border-radius: 8px !important;
    color: #667085;
    font-size: 16px;
    height: 42px !important;
    display: flex;
    align-items: center;
    & :global .ant-select-selection-item{
      font-size: 12px;
      font-weight: 600;
    }
  }
  &__forgotPassword{
    align-self: flex-end;
    color: #075CEB;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 25px;
  }
  &__submit{
    background: #075CEB;
    border: 1px solid #075CEB;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    height: 44px;
    width: 100%;
  }
  &__submitGradient{
    background: linear-gradient(90deg, #0A5BEB 0%, #9233F4 100%);
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-color: transparent !important;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
    height: 44px;
    width: 100%;
  }
  &__submitGradient:hover{
    background: linear-gradient(90deg, #0A5BEB 0%, #9233F4 100%);

  }
  &__goBack{
    color: #D0D5DD;
    text-align: center;
  }
}
.clientForm{
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  background: #f8f7f8;
  border-radius: 4px;
  border: 1px solid #f8f7f8;
  padding: 20px 40px;
  &__mainTitle{
    text-align: center;
    color: #111827;
    font-family: Inter,sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  &__title{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #111827;
    margin-right: 5px;
    margin-bottom: 5px;
  }
  &__items{
    display: flex;
    flex-direction: column;
    p {
      margin-bottom: 0;
      margin-top: 10px !important;
    }
    label {
      margin-left: 0 !important;
    }
  }
  &__subtitle{
    color: #111827;
    font-family: Inter,sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 0 !important;
    margin-top: 5px;
  }
  // &__btn{
  //   background-color: transparent;
  //   border: 2px solid #6b7280;
  //   border-radius: 4px;
  //   color: #6b7280;
  //   cursor: pointer;
  //   font-family: Inter,sans-serif;
  //   font-size: 14px;
  //   font-weight: 600;
  //   line-height: 20px;
  //   margin: 20px auto 0;
  //   padding: 5px 10px;
  //   width: 100%;
  // }
  &__tooltip{
    display: flex;
    align-items: center;
  }
  &__item{
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    & + &{
      margin-top: 10px;
    }
  }
}
.buttonInner{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  &__btn{
    font-family: 'Helvetica',sans-serif;
    font-weight: 700;
    -webkit-font-smoothing: antialiased;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: calc(.27548vw + 12.71074px);
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color .3s,border-color .3s;
    background-color: #4c69ba;
    border: calc(0.06887vw + 0.67769px) solid #4c69ba;
    padding: calc(0.34435vw + 13.38843px) calc(0.34435vw + 18.38843px);
  }
}
.googleBtn{
  color: #000000;
  height: 70px;
  display: flex;
  font-weight: 500;
  align-items: center;
  padding: 1px 35px 1px 1px;
  background-color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 2px;
  transition: all .5s;
  width: 320px;
  font-size: 18px;
  &:hover{
    background-color: #4285F4;
    color: #fff;

  }
}

.tikTokBtn{
  color: #000000;
  height: 70px;
  display: flex;
  font-weight: 500;
  align-items: center;
  padding: 1px 35px 1px 1px;
  background-color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 2px;
  transition: all .5s;
  width: 320px;
  font-size: 18px;
  &:hover{
    background-color: #000000;
    color: #fff;

  }
}
.service{
  display: flex;
  align-items: center;
  padding: 4px 12px 4px 4px;
  background: #F9F5FF;
  mix-blend-mode: multiply;
  border-radius: 16px;
  margin-bottom: 44px;
  &__name{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    padding: 2px 10px;
    background: #075CEB;
    border-radius: 16px;
    margin-right: 12px;
    margin-bottom: 0;
  }
  &__grant{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #075CEB;
    margin-bottom: 0;
  }
}
.multiPermission{
  margin-top: 24px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #101828;
  margin-bottom: 40px;
  max-width: 492px;
  width: 100%;
}
.tableRow{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 56px 16px 24px;
  p{
    margin-bottom: 0;
  }
  p:first-child{
    color: var(--gray-900, #101828);
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 0;
  }
}
.warningIcon{
  width: 35px;
  height: 35px;
  svg{
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 1024px) {
  .formBlock{
    padding: 26px 40px 67px;
  }
}
@media (max-width: 850px) {
  .inner{
    padding: 0 20px;
  }
}
@media (max-width: 650px) {
  .inner{
    &__bigText{
      margin-top: 24px;
      font-size: 20px;
      line-height: 120%;
    }
    &__multiStepBeginning{
      padding: 25px 15px;
    }
  }
  .tableRow{
    flex-direction: column;
    padding: 16px 24px;
  }
}
@media (max-width: 500px) {
  .inner{
    &__bigText{
      font-size: 18px;
    }
    &__smallText{
      font-size: 16px;
    }
    &__multiStepBeginning_steps{
      width: 326px;
    }
  }
}
@media (max-width: 480px) {
  .inner{
    padding: 0 20px;
  }

}
@media (max-width: 415px) {
  .inner{
    padding: 0 8px;
  }
  .formBlock{
    padding: 26px 8px 29px;
  }
}