.inner{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #EDF4FF;
  min-height: 100vh;
  height: 100%;
  position: relative;
  overflow: hidden;
  &__decoration{
    position: absolute;
  }
}
.contentBlock{
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  border-radius: 12px;
  display: grid;
  grid-template-columns: 2fr 3fr;
  position: relative;
  max-width: 900px;
  min-width: 600px;
  width: 50%;
  h1{
    color: #170F49;
    font-size: 32px;
    margin: 30px 0px 12px 0px;
    text-align: center;
    span{
      color: #2752ec;
    }
  }
  img{
    width: 100%;
  }
  &__textWrapper{
    padding: 30px;
  }
  &__separator{
    background: linear-gradient(90deg, #0A5BEB 0%, #9233F4 100%);
    height: 5px;
    width: 122px;
  }
  &__description{
    color: #667085;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
  }
}
@media (max-width: 650px) {
  .inner{
    padding: 0px 10px;
  }
  .contentBlock{
    min-width: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
      h1{
        font-size: 24px;
      }
    img{
      width: 40%;
    }
  }
}