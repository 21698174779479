:root {
  --app-padding-left-right: 60px;
  --app-min-height: 500px;
  --app-primary-color: #1890FF;
  --app-highlight-color: #dce7f2;
  --app-background-color: #FCFEFF;
  --app-dark-color: #2D2D2D;
  --app-text-color: #292929;
  
  --header-height: 72px;
  --footer-height: 170px;
}

@primary-color: #1890FF;@input-height-base: 38px;@input-placeholder-color: #36374a80;@border-radius-base: 5px;@btn-height-base: 38px;@btn-font-weight: 600;@font-size-base: 16px;@form-item-label-font-size: 16px;@btn-default-ghost-color: @primary-color;@btn-default-ghost-border: @primary-color;@popover-padding-horizontal: 0px;@table-bg: transparent;@table-header-bg: transparent;