.wrapper{
  display: flex;
  font-size: 14px;
  width: 100%;
}
.crumb{
  color: rgba(0, 0, 0, 0.45);
  &:hover{
    color: rgba(0, 0, 0, 0.85);
  }
}
.currentCrumb{
  color: rgba(0, 0, 0, 0.85);
  user-select: none;
}
.separator{
  margin: 0px 5px;
}
.tools{
  align-items: center;
  display: flex;
}
