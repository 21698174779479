.title{
  color: var(--gray-900, #101828);
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  margin-bottom: 8px;
  margin-top: 20px;
}
.title_bg{
  color: var(--gray-900, #101828);
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 20px;
  margin-top: 20px;
}
.text{
  color: var(--gray-500, #667085);
  text-align: center;
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 20px;
}
.text_client{
  color: var(--gray-900, #101828);
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  margin-bottom: 20px;
}
.formButtons{
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}
.cancelBtn{
  background: #FFFFFF;
  border: 1px solid #D0D5DD;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  height: 44px;
  width: 100%;
  color: #344054;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
}
.switchComponent{
  margin-bottom: 12px;
}
.switchContainer {
  display: flex;
  align-items: center;
  width: 168px;
  height: 30px;
  position: relative;
  cursor: pointer;
}

.switch {
  position: relative;
  display: flex;
  justify-content: space-between;
  background-color: #ccc;
  cursor: pointer;
  border-radius: 16px;
  background: var(--primary-50, #F9F5FF);
  padding: 4px 12px 4px 4px;
  width: 100%;
  height: 100%;
}
.slider {
  position: absolute;
  cursor: pointer;
  border-radius: 16px;
  height: 22px;
  background-color: #075CEB;
  transition: transform 0.2s ease-in-out;
  top: 4px;
  left: 4px;
  mix-blend-mode: multiply;
  &_right{
    width: 102px;
  }
  &_left{
    width: 58px;
  }
}
.on{
  .slider {
    transform: translateX(59px);
  }
}
.switchLabelLeft{
  color: var(--Primary-Blue, #075CEB);
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  position: absolute;
  left: 11px;
  top: 6px;
  z-index: 10;
  cursor: pointer;
}
.switchLabelRight{
  color: var(--Primary-Blue, #075CEB);
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  position: absolute;
  z-index: 10;
  right: 11px;
  top: 6px;
  cursor: pointer;
}
.switchLabelRight{
  &_on{
    color: #FFFFFF;
  }
  &_of{
    color: var(--Primary-Blue, #075CEB);
  }
}
.switchLabelLeft{
  &_of{
    color: #FFFFFF;
  }
  &_on{
    color: var(--Primary-Blue, #075CEB);
  }
}
.switchText{
  color: var(--gray-700, #344054);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 6px;
}
.clientButtons{
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
}
.clientButtons{
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
}
.clientButtons{
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
}
.selectButton{

}
.modal{
  &__closeBtn{
    border: none;
    background-color: transparent;
    position: absolute;
    top: -20px;
    right: -15px;
    font-size: 20px;
    cursor: pointer;
  }
}
.imageLoaderCheck{
  //border-radius: 50%;
  //border: 1px solid var(--Primary-gradient, #0A5BEB);
  //background: var(--Primary-gradient, linear-gradient(90deg, #0A5BEB 0%, #9233F4 100%));
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all .3s;
  &:hover{
    svg{
      path{
        stroke: rgb(7, 92, 235);
      }
    }
  }
}
.sendLinkImageLoader{

  &__header{
    display: flex;
    justify-content: space-between;
  }
  &__imgInfo{
    margin-left: 16px;
    p:first-child {
      color: var(--gray-700, #344054);
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 142.857% */
      margin-bottom: 0;
    }
    p:nth-child(2){
      color: var(--gray-500, #667085);
      font-family: Inter, sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 0;
    }
  }
}
.percentage{
  color: var(--gray-700, #344054);
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  margin-left: 12px;
}
.formHeader{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2{
    margin-top: 20px;
    color: var(--Gray-900, #101828);
    text-align: center;
    font-family: Inter, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    margin-bottom: 20px;
  }
}

.modal{
  &__closeBtn{
    border: none;
    background-color: transparent;
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
  }
}